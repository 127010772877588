.sidenav-container {
    /* left aligned container, takes up the full screen height */
    /* and 220px width, no transition */
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 235px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0px;
    padding-right: 10px;
    padding-top: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidenav-main-content-container {
    padding-left: 12px;
}

/* less vertical padding when the banner is hidden */
.sidenav-container-no-banner {
    padding-top: 10px;
}

.sidenav-section {
    margin-top: 20px;
}

.sidenav-logo {
    border-radius: 5px;
    width: 35px;
    height: 35px;
    margin-left: 15px;
    cursor: pointer;
}

.sidenav-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
    height: 60px;
}

.sidenav-section-header-text {
    font-family: "Inter";
    font-weight: 600;
    color: var(--light-gray);
    font-size: 12px;
    margin-bottom: 5px;
}

.sidenav-menu-icon-container {
    display: none;
    cursor: pointer;
}

.sidenav-menu-icon {
    font-size: 24px;
    color: var(--off-black);
}

.sidenav-icon {
    font-size: 20px;
    color: var(--medium-gray);
    margin-top: 2px;
}

.sidenav-icon-selected {
    color: var(--off-black);
}

.sidenav-tablet-logo-menu-icon-container {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 25px;
}

.sidenav-row, .sidenav-section-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 18x;
    padding: 5px 10px;
}

.sidenav-section-header-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    cursor: default;
}

.sidenav-row:hover, .sidenav-row-selected {
    background-color: var(--divider-line-gray);
    border-radius: 5px;
}

.sidenav-row:hover .sidenav-icon, .sidenav-row:hover .sidenav-text {
    color: var(--off-black);
}

.sidenav-row-social-icon-tablet-hidded {
    display: flex;
}

.sidenav-row-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    bottom: 0px;
    left: 5px;
    width: 100%;
}

.sidenav-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: calc(100% - 10px);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 12px;
}

.sidenav-name-container:hover {
    background-color: var(--divider-line-gray);
    border-radius: 5px;
}

.sidenav-row-bottom-hidden {
    /* For large screen sizes, we still want this shown */
    display: flex;
}

.sidenav-account-options-container {
    position: absolute;
    top: -95px;
    left: 2px;
    width: 230px;
    background-color: white;
    padding: 5px 2px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.user-name-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--light-gray);
    cursor: pointer;
}

.user-name-icon-text {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    color: var(--off-black);
    margin-top: 0px;
    margin-bottom: 1px;
}

.user-name-text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: var(--medium-gray);
    margin-top: 0px;
    margin-bottom: 1px;
    cursor: pointer;
}

.sidenav-text, .sidenav-text-selected {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--medium-gray);
    margin: 0px 20px;
    margin-right: 10px;
    margin-bottom: 3px;
}

.sidenav-text-selected {
    color: var(--off-black);
}

.sidenav-text-opened {
    display: block;
}



@media screen and (max-width: 1200px) {

    .sidenav-container {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        width: 65px;
        padding-right: 8px;
    }

    .sidenav-main-content-container {
        padding-left: 8px;
    }

    .sidenav-logo {
        width: 25px;
        height: 25px;
        margin-left: 10px;
    }

    .sidenav-section-header-text {
        display: none;
    }

    .sidenav-row-bottom {
        left: 12px;
    }

    .sidenav-name-container {
        padding: 8px 5px;
        width: fit-content;
    }

    .user-name-icon {
        margin-right: 0px;
    }

    .sidenav-account-options-container {
        position: absolute;
        top: -110px;
        left: -5px;
        width: fit-content;
        background-color: white;
        padding: 0px 2px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    .user-name-text {
        display: none;
    }

    .sidenav-text, .sidenav-text-selected {
        display: none;
    }

}
