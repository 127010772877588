.chat-main-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
}

.chat-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 3;
    padding-top: 0px;
    height: 100vh;
}

.chat-main-container-banner-shown {
    height: calc(100vh - 45px);
}

.chat-messages-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
}

/* Hide the scroll bar */
.chat-messages-container::-webkit-scrollbar {
    display: none;
}

.chat-send-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    padding: 10px 60px 20px 60px;
}

.chat-sidebar-container-light, .chat-sidebar-container-dark {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    flex: 1;
    border-left: 1px solid var(--divider-line-gray);
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 10px;
    height: 100vh;
    overflow: hidden;
}

.chat-sidebar-container-dark {
    border-left: 1px solid var(--dark-theme-off-black);
}

.chat-sidebar-container-banner-shown {
    height: calc(100vh - 95px);
}

.chat-message-container-light, .chat-message-container-dark {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 25px 40px 25px 80px;
    border-bottom: 1px solid var(--divider-line-gray);
}

.chat-message-container-dark {
    border-bottom: 1px solid var(--dark-theme-off-black);
}

.chat-message-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;
    /* 3 to 1 flex ratio, minus the left sidebar width, minus the left and right padding on the message container */
    /*max-width: calc(75vw - 360px);*/
    margin: auto;
    text-align: left;
}

.chat-message-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 850px;
    margin: auto;
}

.view-previous-chats-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.view-previous-chats-text-light, .view-previous-chats-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    margin-right: 10px;
    cursor: pointer;
}

.view-previous-chats-text-light:hover {
    color: var(--darker-blue);
}

.chat-message-copy-icon-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.copy-text-icon-light, .copy-text-icon-dark {
    color: var(--medium-gray);
    cursor: pointer;
    font-size: 20px;
}

.copy-text-icon-dark {
    color: var(--light-gray);
}

.copy-text-icon-light:hover {
    color: var(--off-black);
}

.copy-text-icon-dark:hover {
    color: var(--divider-line-gray);
}

.copied-message-text {
    color: var(--off-black);
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
}

.ai-chat-message-container-light {
    background-color: var(--off-white);
}

.ai-chat-message-container-dark {
    background-color: var(--dark-theme-background-color);
}

.user-chat-message-container-light {
    background-color: white;
    border-bottom: 1px solid var(--divider-line-gray);
}

.user-chat-message-container-dark {
    background-color: var(--dark-theme-card-background);
    border-bottom: 1px solid var(--dark-theme-off-black);
}

.chat-message-header-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--medium-gray);
    margin-top: 5px;
    margin-bottom: 5px;
}

.chat-message-text-light, .chat-message-text-dark {
    font-family: "Inter";
    /*white-space: pre-wrap;*/
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--off-black);
    margin-top: 0px;
    margin-bottom: 0px;
    overflow-x: hidden;
    width: 100%;
}

.chat-message-text-light h1, .chat-message-text-dark h1 {
    font-size: 32px;
    line-height: 44px;
}

.chat-message-text-light h2, .chat-message-text-dark h2 {
    font-size: 26px;
    line-height: 36px;
}

.chat-message-text-light h3, .chat-message-text-dark h3 {
    font-size: 22px;
    line-height: 32px;
}

.chat-message-text-dark {
    color: var(--divider-line-gray);
}

.new-chat-thread-button-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    padding-right: 30px;
}

.all-chat-threads-container {
    display: flex;
    flex: 10;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 30px;
    overflow-y: auto;
}

.chat-thread-container-light, .chat-thread-container-dark {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 15px;
    padding-left: 0px;
    border: 1px solid var(--divider-line-gray);
    border-radius: 5px;
    margin-top: 6px;
}

.chat-thread-container-dark {
    border: 1px solid var(--off-black);
}

.chat-thread-title-col {
    display: flex;
    flex-direction: row;
    flex: 8;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
}

.chat-thread-title-col-disabled {
    cursor: default;
}

.chat-thread-title-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.chat-thread-edit-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.delete-chat-thread-col {
    padding: 0px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    position: relative;
}

.edit-delete-chat-container-light, .edit-delete-chat-container-dark {
    position: absolute;
    top: 25px;
    left: -145px;
    width: 170px;
    background-color: white;
    padding: 5px 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.edit-chat-thread-ellipsis-light, .edit-chat-thread-ellipsis-dark {
    padding: 0px 0px;
    cursor: pointer;
    height: 18px;
}

.edit-chat-thread-icon-light, .delete-chat-thread-icon  {
    color: var(--off-black);
    font-size: 15px;
    margin-right: 15px;
}

.delete-chat-thread-icon {
    color: var(--delete-red);
}

.edit-chat-thread-text-light, .delete-chat-thread-text {
    color: var(--off-black);
    cursor: pointer;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

.delete-chat-thread-text {
    color: var(--delete-red);
}

.edit-delete-row {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
}

.edit-delete-chat-container-dark {
    background-color: var(--dark-theme-card-background);
}

.delete-chat-thread-col-disabled {
    cursor: default;
}

.delete-chat-thread-icon-light {
    display: none;
    color: var(--medium-gray);
}

.delete-chat-thread-icon-dark {
    display: none;
    color: var(--light-gray);
}

.chat-thread-container-light:hover .delete-chat-thread-icon-light {
    display: block;
}

.chat-thread-container-dark:hover .delete-chat-thread-icon-dark {
    display: block;
}

.delete-chat-thread-col:hover .delete-chat-thread-icon-light{
    color: var(--off-black);
}

.delete-chat-thread-col:hover .delete-chat-thread-icon-dark{
    color: var(--divider-line-gray);
}

.chat-thread-container-selected-light {
    background-color: #D8D8D8;
}

.chat-thread-container-selected-dark {
    background-color: var(--dark-theme-card-background-hover);
}

.chat-thread-container-light:hover {
    background-color: var(--divider-line-gray);
    border: 1px solid var(--light-gray);
}

.chat-thread-container-dark:hover {
    background-color: var(--dark-theme-card-background-hover);
    border: 1px solid var(--dark-gray);
}

.chat-thread-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-thread-date-text-light, .chat-thread-date-text-dark {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    color: var(--medium-gray);
    margin-top: 12px;
    margin-bottom: 2px;
}

.chat-config-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.chat-config-text-light, .chat-config-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.chat-config-text-dark {
    color: var(--dark-theme-clickable-text-blue);
}

.chat-config-text-light:hover {
    font-weight: 700;
    color: var(--darker-blue);
}

.chat-config-text-dark:hover {
    font-weight: 700;
    color: var(--dark-theme-clickable-text-blue-hover);
}


.new-chat-thread-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.new-chat-thread-modal-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
}

.new-chat-thread-modal-button-row {
    width: 100%;
    margin-top: 10px;
}

.copy-markdown-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: -40px;
    margin-right: 6px;
    cursor: pointer;
}

.copied-text {
    /* show a transition when this text is displayed */
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    color: white;
    line-height: 22px;
    text-align: right;
    margin-top: 0px;
    margin-bottom: 5px;
    z-index: 10;
}



.chat-result-type-text-light, .chat-result-type-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 20px;
    color: var(--medium-gray);
    margin-right: 100px;
    cursor: pointer;
}

.chat-result-type-text-dark {
    color: var(--light-gray);
}

.chat-result-type-text-light-selected, .chat-result-type-text-dark-selected {
    font-family: "Inter";
    font-weight: 700;
    font-size: 20px;
    color: var(--off-black);
    margin-right: 100px;
    /*text-decoration: underline;*/
    /* make the underline bolder, and have a larger offset */
    /*text-decoration-thickness: 2px;
    text-underline-offset: 5px;*/
}

.chat-result-type-text-dark-selected {
    color: var(--divider-line-gray);
}

.chat-result-type-text-light:hover {
    color: var(--off-black);
}

.chat-result-type-text-dark:hover {
    color: var(--divider-line-gray);
}

.view-queries-and-results-light, .view-queries-and-results-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    cursor: pointer;
    margin-top: 6px;
}

.view-queries-and-results-light:hover {
    color: var(--darker-blue);
    font-weight: 700;
}

.view-queries-and-results-dark {
    color: var(--dark-theme-clickable-text-blue);
}

.view-queries-and-results-dark:hover {
    color: var(--dark-theme-clickable-text-blue-hover);
    font-weight: 700;
}


.sources-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
}

.source-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    padding: 0px 15px;
    cursor: pointer;
    background-color: rgba(93, 36, 216, 0.15);
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 275px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sources-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.source-text-light, .source-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
    margin-bottom: 8px;
}

.source-text-dark {
    color: var(--dark-theme-clickable-text-blue);
}

.source-row:hover {
    background-color: rgba(93, 36, 216, 0.3);
}

.query-result-card-header-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 5px;
}

.query-result-title-container-light, .query-result-title-container-dark {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    padding: 0px 5px;
    /*cursor: pointer;
    background-color: rgba(93, 36, 216, 0.15);*/
    border-radius: 5px;
}

.chat-result-title-text-light {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    color: var(--off-black);
    margin-top: 8px;
    margin-bottom: 8px;
}

.query-search-card-container-light {
    align-items: center;
    border: 1px solid var(--divider-line-gray);
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #0003;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 20px 5px 0;
    max-width: 100%;
    overflow: hidden;
    padding: 10px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.query-search-card-section {
    display: flex;
    flex-direction: column;
}



@media screen and (max-width: 1450px)  {

    .chat-main-container {
        flex: 3;
    }

    .chat-message-content-container, .chat-message-header-container {
        /* 3 to 1 flex ratio, minus the left and right padding on the message container */
        max-width: calc(75vw - 340px);
    }
    
}

@media screen and (max-width: 1200px) {

    .chat-message-container-light, .chat-message-container-dark {
        padding: 25px 40px 25px 40px;
    }

    .chat-message-content-container, .chat-message-header-container {
        /* 3 to 1 flex ratio, minus the left and right padding on the message container */
        max-width: calc(75vw - 65px);
    }

    .chat-main-container {
        flex: 3;
    }

    .chat-sidebar-container {
        padding-left: 25px;
    }

    .chat-send-message-container {
        padding: 10px 40px 20px 40px;
    }

}