
.modal-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.modal-container-light, .modal-container-dark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--off-white);
    padding: 10px 20px;
    padding-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 60%;
    /* Width in proportion to its parent container*/
    max-width: 750px;
    height: auto;
    /* Height in proportion to its parent container */
    margin: auto;
    display: flex;
    flex-direction: column;
}

.modal-container-dark {
    background-color: var(--dark-theme-background-color);
}

.modal-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.modal-content-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.modal-main-content-container {
    display: flex;
    padding: 20px 30px;
    width: 100%;
    max-height: calc(95vh - 120px);
}

.modal-header-text {
    font-family: "Inter";
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 20px;
    margin-top: 3px;
    color: var(--off-black);
}

.modal-dummy-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
}

.modal-close-button {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.modal-close-x-light, .modal-close-x-dark {
    font-family: "Inter";
    font-size: 28px;
    font-weight: 300;
    color: var(--medium-gray);
    margin: 0px;
    margin-bottom: 2px;
}

.modal-close-x-dark {
    color: var(--light-gray);
}

.modal-close-button:hover .modal-close-x-light {
    color: var(--off-black);
    font-weight: 500;
}

.modal-close-button:hover .modal-close-x-dark {
    color: var(--divider-line-gray);
    font-weight: 500;
}

.modal-header-col {
    /*  */
    display: flex;
    flex: 12;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px) {

    .modal-container {
        width: 70%;
    }

}

@media screen and (max-width: 1000px) {

    .modal-container-light, .modal-container-dark {
        width: 80%;
    }

    .modal-main-col {
        flex: 15;
    }

}

@media screen and (max-width: 600px) {

    .modal-container-light, .modal-container-dark {
        width: 90%;
    }

    .modal-main-content-container {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
    }

    .modal-main-col {
        flex: 15;
    }

}
