
.chat-welcome-message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 0px 60px;
}

.chat-welcome-message-logo {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
}

.chat-welcome-message {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--off-black);
    margin: 0px;
    text-align: left;
    white-space: pre-wrap;
}
