
.chat-input-container{
    /* Center the child elements */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 10px;
}

.chat-input-light, .chat-input-dark {
    /* make the height of input field change when the text takes up the entire width */
    height: unset;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 15px;
    font-family: "Inter";
    border: 0px;
    border-radius: 10px;
    overflow-y: visible;
    resize: none;
}

.chat-input-dark {
    background-color: var(--dark-theme-card-background);
    color: var(--divider-line-gray);
}

.chat-input-row-light, .chat-input-row-dark {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.chat-input-row-dark {
    background-color: var(--dark-theme-card-background);
}

.chat-input-light:focus, .chat-input-dark:focus {
    outline: none;
}

.chat-button-container {
    /* display the button container  */
    display: flex;
    margin-left: 30px;
}

.send-icon-container {
    padding-right: 20px;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 12px;
}

.send-icon-light, .send-icon-dark {
    color: var(--message-blue);
    font-size: 18px;
    margin-top: 3px;
    /* rotate the icon */
    transform: rotate(45deg);
}

.send-icon-dark {
    color: var(--light-gray);
}

.send-icon-container:hover .send-icon-light {
    color: var(--darker-blue);
}

.send-icon-container:hover .send-icon-dark {
    color: var(--divider-line-gray);
}

.character-display-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.chat-bottom-row-col {
    display: flex;
    flex: 1;
}

.reset-chat-container {
    display: flex;
    justify-content: center;
}

.character-display-container {
    display: flex;
    justify-content: flex-end;
}

.character-display {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    color: #000;
}

.reset-chat-text {
    font-family: "Inter";
    font-weight: 500;
    font-size: 15px;
    color: var(--off-black);
    cursor: pointer;
}


@media screen and (max-width: 1200px) {

    .chat-input-container {
        /* this needs to be sticky to the bottom of the page, with a width of 50% and centered */
        width: 100%;
    }

}

/*@media screen and (max-width: 1024px) {

    .chat-input-container {
        width: 70%;
    }

}

@media screen and (max-width: 820px) {

    .chat-input-container {
        width: 75%;
    }

}*/


@media screen and (max-width: 550px) {

    .chat-input-container {
        width: 90%;
    }

}