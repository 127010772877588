
.delete-confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.delete-confirmation-modal-main-col {
    width: 100%;
    padding: 0px 10px;
}

.delete-confirmation-modal-cancel-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 35px;
    margin-top: 12px;
    cursor: pointer;
}

.delete-confirmation-modal-cancel-text {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}