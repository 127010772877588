/*
.chat-message-container {
    width: 600px;
    border-radius: 8px;
    padding: 0px 15px;
    background-color: white;
    box-shadow: 0 0 6px 3px rgba(200, 200, 200, 0.2), 0 5px 5px 0 rgba(100, 100, 100, 0.24);
}

.chat-message-container-right {
    background-color: var(--message-blue);
    border: none;
}*/

.thinking-chat-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    width: 40px;
}

.thinking-dots {
    font-family: "Inter";
    font-weight: 500;
    font-size: 34px;
    margin-bottom: 54px;
    color: var(--medium-gray);
}

.chat-message-text-right p, .chat-message-text-left p {
    margin: 0px;
}

.chat-message-text-left {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    color: rgba(56, 56, 56, 1);
    line-height: 22px;
    white-space: pre-line;
    word-wrap: break-word;
    padding-top: 15px;
    padding-bottom: 15px;
}

.chat-message-text-right {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    color: #F2F2F7;
    line-height: 22px;
    white-space: pre-line;
    word-wrap: break-word;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-x: auto;
}

.copy-markdown-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: -40px;
    margin-right: 6px;
    cursor: pointer;
}

.copied-text {
    /* show a transition when this text is displayed */
    transition: opacity 0.5s;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    color: var(--off-black);
    line-height: 22px;
    text-align: right;
    margin-top: -36px;
}

/*@media screen and (max-width: 1300px) {

    .chat-message-container {
        width: 80%;
    }

}

@media screen and (max-width: 820px) {

    .chat-message-container {
        width: 80%;
        border-radius: 8px;
        padding: 0px 15px;
        box-shadow: 0 0 6px 3px rgba(200, 200, 200, 0.2), 0 5px 5px 0 rgba(100, 100, 100, 0.24);
    }

}


@media screen and (max-width: 600px) {

    .chat-message-container {
        width: 100%;
    }

}*/