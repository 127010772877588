

.chat-result-card-container-light, .chat-result-card-container-dark {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-bottom: 25px;
}

.chat-result-card-container-dark {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.8);
}

.chat-result-title-text-light, .chat-result-title-text-dark {
    color: var(--off-black);
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
}

.chat-result-title-text-dark {
    color: var(--divider-line-gray);
}

.query-result-link {
    text-decoration: underline;
    color: var(--message-blue);
    cursor: pointer;
}

.query-result-link:hover {
    color: var(--darker-blue)
}