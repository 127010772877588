
.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    padding-bottom: 50px;
    margin-top: 30px;
}

.dashboard-logo-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
}

.dashboard-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 25px;
    margin-bottom: 20px;
}

.dashboard-col, .dashboard-center-col {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0px;
    margin: 0px 8px;
    border: 2px solid #222575;
    border-radius: 10px;
}

.dashboard-center-col {
    flex: 2;
}

.dashboard-font, .dashboard-font-bold {
    font-family: "Inter";
    color: #222575;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 23px;
    text-align: center;
}

.dashboard-underline-text {
    text-decoration: underline;
    /* Add an offset to the underline */
    text-underline-offset: 3px;
}

.dashboard-font-bold {
    font-weight: 700;
}

.three-dots-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin: auto;
    margin-top: 25px;
}

.three-dots {
    display: block;
    width: 18px;
    height: 18px;
    background-color: #222575;
    border-radius: 18px;
}

.dashboard-col-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #222575;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 10px 15px;
    width: 100%;
    height: 102px;
}

.dashboard-strategy-header-font {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F2F2F7;
    margin: 0px;
    text-align: center;
}

.dashboard-col-strategies-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 15px;
}

.clickable-strategy-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DBDCE7;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.clickable-strategy-container:hover {
    background-color: #c2c4d1;
}

.clickable-strategy-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: #222575;
    margin: 0px;
    padding: 10px 5px;
    text-align: center;
}

.dashboard-col-strategies-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dashboard-col-strategies-left-col, .dashboard-col-strategies-right-col {
    display: flex;
    flex: 1;
}

.dashboard-col-strategies-left-col {
    margin-right: 8px;
}

.dashboard-col-strategies-right-col {
    margin-left: 8px;
}