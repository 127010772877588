.login-redirect-container {
    /* Center the contents inside this container */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.login-redirect-header-container {
    margin-bottom: 150px;
}

.login-redirect-header {
    font-family: "Inter";
    font-size: 30px;
    font-weight: 800;
    color: var(--message-blue);
}

.email-password-login-help-text {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    color: var(--off-black);
}

.return-to-login-page {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    color: var(--message-blue);
    margin-top: 20px;
    cursor: pointer;
}