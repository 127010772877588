.homepage-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.homepage-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 40px;
}

.header-icon-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0px 140px;
}

#header-logo {
    border-radius: 5px;
    width: 35px;
    height: 35px;
}

#homepage-login-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#homepage-login-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    color: #222575;
    margin-right: 20px;
    cursor: pointer;
    width: 80px;
}

#homepage-login-text:hover {
    font-weight: 700;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 950px;
}

.homepage-main-text {
    font-family: "Inter";
    font-weight: 700;
    font-size: 48px;
    color: #222575;
    margin-top: 0px;
    margin-bottom: 30px;
}

.homepage-sub-text, .homepage-sub-text-bold {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #222575;
    margin-top: 10px;
    text-align: center;
}

.homepage-sub-text-bold {
    font-weight: 700;
}

.homepage-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 10px;
    width: 170px;
}

#homepage-logo {
    width: 70px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.homepage-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 20px;
}

.homepage-icon {
    font-size: 32px;
    color: #222575;
}