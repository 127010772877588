/* This property changes how the total width and height of an element is calculated. 
Instead of adding the padding and border to the element's specified width and height, 
it includes the padding and border in the element's total width and height. */
* {
    box-sizing: border-box;
}


.small-screen-warning {
    display: none;
}

.dashboard-wrapper-container {
    /* Full width,  */
    display: flex;
    width: 100%;
    /*padding-top: 45px;*/
    min-height: 100vh;
    background-color: var(--off-white);
}

.dashboard-wrapper-container-dark {
    background-color: var(--dark-theme-background-color);
}

.dashboard-wrapper-main-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 235px;
    width: calc(100% - 190px);
    max-width: 1400px;
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 0px;
    padding-top: 50px;
    overflow-x: hidden;
}


@media screen and (max-width: 1200px) {

    .dashboard-wrapper-container {
        padding-top: 0px;
    }

    .dashboard-wrapper-main-content-container {
        margin-left: 65px;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

    }

    /*.dashboard-wrapper-sidenav-container-tablet {
        display: flex;
    }

    .dashboard-wrapper-sidenav-container {
        display: none;
    }*/

}

@media screen and (max-width: 750px) {

    .small-screen-warning {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        min-height: 100vh;
    }

    .dashboard-wrapper-main-content-container, .dashboard-wrapper-container {
        display: none;
    }

    .dashboard-wrapper-sidenav-container {
        display: none;
    }

}